import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Plugins, Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { LoadingController } from '@ionic/angular';
import { ProjectService } from './project.service';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular'; 

import * as $ from 'jquery';
import * as moment from 'moment';

import { set, get, remove } from "./services/storage";

@Injectable({
  providedIn: 'root'
})
export class SavestateService {
	public sketchFiles: Sketch[] = [];
	private SKETCHES_STORAGE: string = "stored_sketches";
	private loading: any;
	public error: string;
	public is_web: any;
	public session_key: string;
	public sketch_counter = 0;
	public timestamp: any;

	public projects: any;
	public buildings: any;
	public building_temperature: any;
	public damages: any;
	public notes: any;
	public sketches: any;
	public examples: any;
	public componentgroups: any;
	public damagetypes: any;

	constructor(
		private sanitizer: DomSanitizer,
		public api: ProjectService, 
		public loadingController: LoadingController, 
		private readonly http: HttpClient,
		public toastController: ToastController) 
	{
		if (Capacitor.getPlatform() === 'web') {
      this.is_web = true;
    } else {
      this.is_web = false;
    }		
	}

	public getImgContent(url) {
	  return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	public germanDate(date) {
		let date_ger = moment(date).format('DD.MM.y');
		return date_ger;
	}

	public germanDateTime(date) {
		let date_ger = moment(date).format('DD.MM.y HH:mm');
		return date_ger;
	}

	public async reloadData() {
		// Session Key
		let session_key = await	get('session_key');
		if(session_key) {
			(window as any).session_key = session_key;
			this.session_key = session_key;
	    console.log("Session Key", session_key);

	    // Timestamp of last sync
			let timestamp = await	get('last_sync');
			(window as any).timestamp = timestamp;
			this.timestamp = timestamp;
	    console.log("Timestamp", timestamp);    

	    // Relevant Data
	    let projects = await get('projects');
			this.projects = projects;
	    console.log("Projects", projects);  

	    let buildings = await get('buildings');
			this.buildings = buildings;
	    console.log("Buildings", buildings);  

	    let damages = await get('damages');
			this.damages = damages;
	    console.log("damages", damages);  

	    let notes = await get('notes');
			this.notes = notes;
	    console.log("notes", notes);  

	    let sketches = await get('sketches');
			this.sketches = sketches;
	    console.log("sketches", sketches); 

	    let componentgroups = await get('componentgroups');
			this.componentgroups = componentgroups;
	    console.log("componentgroups", componentgroups); 

	    let damagetypes = await get('damagetypes');
			this.damagetypes = damagetypes;
	    console.log("damagetypes", damagetypes); 

	    let examples = await get('examples');
			this.examples = examples;
	    console.log("examples", examples); 
		} else {
			// No Session Key has been found => Redirect to login and do nothing
			// alert("No Session Key - Back to Login!!");
		}
	}

	public getDistance(x1, x2, y1, y2) {
			console.log("Calculating Distance!");
	    let y = x2 - x1;
	    let x = y2 - y1;
	    console.log("Distance: ", Math.sqrt(x * x + y * y));
	    return Math.sqrt(x * x + y * y);
	}

	public check() : any {
		get('projects').then((val) => {
			if(val) {
				return true; 
			} else {
				return false; 
			}
		});
	}

 	public save() {
		// Write Global Objects as JSON to localstorage
		console.log("Lokaler Speicher wird überschrieben");

		set('projects', this.projects);
		set('buildings', this.buildings);
		set('damages', this.damages);
		set('notes', this.notes);
		set('sketches', this.sketches);
		set('componentgroups', this.componentgroups);
		set('damagetypes', this.damagetypes);
		set('examples', this.examples);
		set('last_sync', this.timestamp);

		return; 
  }

 	public create(res) {
		// Write Global Objects as JSON to localstorage
		console.log("Lokaler Speicher wird erstellt");

		this.projects = res.projects;
		this.buildings = res.buildings;
		this.damages = res.damages;
		this.notes = res.notes;
		this.sketches = res.sketches;
		this.componentgroups = res.componentgroups;
		this.damagetypes = res.damagetypes;
		this.examples = res.examples;

		set('projects', res.projects);
		set('buildings', res.buildings);
		set('damages', res.damages);
		set('notes', res.notes);
		set('sketches', res.sketches);
		set('componentgroups', res.componentgroups);
		set('damagetypes', res.damagetypes);
		set('examples', res.examples);

		this.storeSketches();

		return; 
  }

	public async writeDownload(imageBlob, filename, id, self) {
		console.log("Write Download for Sketch!");
		const base64 = await self.convertBlobToBase64(imageBlob);

		const file = await Filesystem.writeFile({
      path: filename,
			data: String(base64),
			directory: Directory.Data
		});
		
		const finalPhotoUri = await Filesystem.getUri({
      directory: Directory.Data,
      path: filename
    });

		let image_show_url = Capacitor.convertFileSrc(finalPhotoUri.uri);
		
		this.sketch_counter++;

		this.presentToast(this.sketch_counter + " von " + Object.keys(this.sketches).length + " Skizzen wurde heruntergeladen");

		set('sk-' + id, image_show_url);
		set('sk-' + id + '-local', finalPhotoUri.uri);

		self.sketchFiles.unshift({
		  filepath: file.uri,
		  webviewPath: image_show_url,
		  id: id
		});

		set(self.SKETCHES_STORAGE, self.sketchFiles);
		
		var sketch_index = this.sketchFiles.findIndex(function(sketch) {return sketch.id == id});
		console.log("Index", sketch_index);
		var sketch = this.sketchFiles[sketch_index].local_url = image_show_url;
		self.save();

		console.log('Set URL: ' + image_show_url + 'for sk ' + id);
	}

  public async singleDownload(sketch) {
  	console.log("Single Download", sketch);
		var id = sketch.id,
			filename = sketch.image_path.replace(/^.*[\\\/]/, ''),
			extension = 'jpg',
			full_path = sketch.image_path,
			self = this;

		console.log("Full path", full_path);

		get('sk-' + id).then(function(path) {
			if(!path) {
				self.http.get(full_path, {responseType: 'blob'})
			    .subscribe((imageBlob: Blob) => {
			    	console.log("Image got downloaded as Blob");
			        self.writeDownload(imageBlob, filename, id, self);
			    });
			}
		});
  }

 	public deleteSketches() {
 		get(this.SKETCHES_STORAGE).then(function(sketchFiles) {
 			console.log(sketchFiles);
 			sketchFiles.forEach(function(sketch) {
 				console.log(sketch);
 				var path = sketch.filepath;
 				remove('sk-' + sketch.id);
 				this.deleteFile(path).then(function(e) {
 					console.log(e);
 					console.log("Sketch has been deleted");
 				});
 			});
 		});
 	}

 	public async deleteFile(path) {
		return await Filesystem.deleteFile({
			path: path,
			directory: Directory.Data
		});	
 	}

 	public storeSketches() {
		// Write Global Objects as JSON to localstorage
		console.log("Skizzen werden heruntergeladen und lokal gespeichert.");
		console.log(this.sketches);
		for (var key in this.sketches) {
		    // skip loop if the property is from prototype
		    if (!this.sketches.hasOwnProperty(key)) continue;

		    var sketch = this.sketches[key];
		    console.log(sketch);
		    this.singleDownload(sketch);
		}
  }

  async syncDown() {
		const loading = await this.loadingController.create();
		await loading.present();
		return new Promise((resolve, reject) => { 
			this.api.getProjects()
			.subscribe(res => {
				console.log(res);
				this.create(res);

		  	var currentDate = new Date();

				this.timestamp = "Letzte Synchronisierung: " + currentDate.getDate() + "." + (currentDate.getMonth() + 1) + "." + currentDate.getFullYear() + " um " + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds() + "Uhr";
				set('last_sync', this.timestamp);	
				loading.dismiss();

				resolve(res.projects);
			}, err => {
			  console.log(err);
			  loading.dismiss();
			});
		});
	}

  async syncUp() {
	  console.log("Sync Up");
		const loading = await this.loadingController.create();
		await loading.present();
		return new Promise((resolve, reject) => { 
			this.api.pushDamages(this.damages, this.notes)
			.subscribe(res => {
				loading.dismiss();
				resolve(res);
			}, err => {
			  console.log(err);
			  loading.dismiss();
			});
		});
	}

	// Helpers
	convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
		const reader = this.getFileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result);
		};
		return reader.readAsDataURL(blob);
	});

	getFileReader(): FileReader {
		const fileReader = new FileReader();
		const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
		return zoneOriginalInstance || fileReader;
	}

	async presentToast(message) {
		const toast = await this.toastController.create({
		  message: message,
		  duration: 2000
		});
		toast.present();
	}

 	isEqual(value, other) {
    var self = this;
    var type = Object.prototype.toString.call(value);
    if (type !== Object.prototype.toString.call(other)) return false;

    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    var compare = function (item1, item2) {
      var itemType = Object.prototype.toString.call(item1);
      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!self.isEqual(item1, item2)) return false;
      } else {
        if (itemType !== Object.prototype.toString.call(item2)) return false;
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }
      }
    };

    if (type === '[object Array]') {
      for (var i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }

    return true;
  }
}

// Interfaces
interface Sketch {
  filepath: string;
  webviewPath: string;
  id: number;
  base64?: string;
  local_url?: string;
}
