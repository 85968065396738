import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
	debug = true;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {}

  private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
		  	`Backend returned code ${error.status}, ` +
		  	`body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}

	private extractData(res: Response) {
	  let body = res;
	  return body || { };
	}

	public sendFeedback(feedback): Observable<any> {
		console.log();
		if(this.debug) {
			var url_base = (window as any).apiUrlDebug;
		} else {
			var url_base = (window as any).apiUrl;
		}

		var data = {
			'session': (window as any).session_key,
			'text': feedback
		};
		
		return this.http.post(url_base + 'feedback', data, httpOptions).pipe(
			map(this.extractData),
			catchError(this.handleError));
	}
	
	public getProjects(): Observable<any> {
		var data = {
			'session': (window as any).session_key
		};
		
		if(this.debug) {
			var url_base = (window as any).apiUrlDebug;
		} else {
			var url_base = (window as any).apiUrl;
		}
		
		return this.http.post(url_base + 'projects', data, httpOptions).pipe(
			map(this.extractData),
			catchError(this.handleError));
	}

	public pushNewImages(): Observable<any> {
		// Iterate over all new image and create a form date with an instruction array and an images array
		if(typeof((window as any).new_images) != "undefined") {
			// NOT YET DONE
			var data = {};

			if(this.debug) {
				var url_base = (window as any).apiUrlDebug;
			} else {
				var url_base = (window as any).apiUrl;
			}

			return this.http.post(url_base + 'sync-images', data, httpOptions).pipe(
				map(this.extractData),
				catchError(this.handleError));
		}
	}	

	public pushDamages(damages, notes): Observable<any> {

		var data = {
			'session': (window as any).session_key,
			'damages': damages,
			'notes': notes
		};

		if(this.debug) {
			var url_base = (window as any).apiUrlDebug;
		} else {
			var url_base = (window as any).apiUrl;
		}

		return this.http.post(url_base + 'sync-damages', data, httpOptions).pipe(
			map(this.extractData),
			catchError(this.handleError));
	}	
}
