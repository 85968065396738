import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then( m => m.ListPageModule)
  },
  { 
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then( m => m.ProjectsPageModule)
  },
  { 
    path: 'project/:id',
    loadChildren: () => import('./project/project.module').then( m => m.ProjectPageModule)
  },
  { 
    path: 'building/:id',
    loadChildren: () => import('./building/building.module').then( m => m.BuildingPageModule)
  },
  { 
    path: 'sketch/:id',
    loadChildren: () => import('./sketch/sketch.module').then( m => m.SketchPageModule)
  },
  { 
    path: 'sketch-relocate/:id/:damage',
    loadChildren: () => import('./sketch-relocate/sketch-relocate.module').then( m => m.SketchRelocatePageModule)
  },
  { 
    path: 'damages/:id',
    loadChildren: () => import('./damages/damages.module').then( m => m.DamagesPageModule)
  },
  { 
    path: 'damage/:id',
    loadChildren: () => import('./damage/damage.module').then( m => m.DamagePageModule)
  },
  { 
    path: 'note/:id',
    loadChildren: () => import('./note/note.module').then( m => m.NotePageModule)
  },
  { 
    path: 'new-damage/:type/:id',
    loadChildren: () => import('./new-damage/new-damage.module').then( m => m.NewDamagePageModule)
  },
  { 
    path: 'sketch-damages/:id',
    loadChildren: () => import('./sketch-damages/sketch-damages.module').then( m => m.SketchDamagesPageModule)
  },
  { 
    path: 'new-note/:type/:id',
    loadChildren: () => import('./new-note/new-note.module').then( m => m.NewNotePageModule)
  },
  { 
    path: 'examples',
    loadChildren: () => import('./examples/examples.module').then( m => m.ExamplesPageModule)
  },
  { 
    path: 'sync',
    loadChildren: () => import('./sync/sync.module').then( m => m.SyncPageModule)
  },
  { 
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  { 
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
   },
  {
    path: 'draw',
    loadChildren: () => import('./draw/draw.module').then( m => m.DrawPageModule)
  },
  {
    path: 'sketch-damage/:id/:damage',
    loadChildren: () => import('./sketch-damage/sketch-damage.module').then( m => m.SketchDamagePageModule)
  },
  {
    path: 'sketch-damage-add/:id/:damage',
    loadChildren: () => import('./sketch-damage-add/sketch-damage-add.module').then( m => m.SketchDamageAddPageModule)
  },
  {
    path: 'sketch-damage-paint/:id/:damage',
    loadChildren: () => import('./sketch-damage-paint/sketch-damage-paint.module').then( m => m.SketchDamagePaintPageModule)
  },
  {
    path: 'issue-report',
    loadChildren: () => import('./issue-report/issue-report.module').then( m => m.IssueReportPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then( m => m.FeedbackPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
