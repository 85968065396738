import { Component } from '@angular/core';
import { SavestateService } from './savestate.service';
import { Platform } from '@ionic/angular';

(window as any).apiUrl = "https://webservice.monitoring-app.de/api/";
(window as any).storageUrl = "https://webservice.monitoring-app.de/uploads/";
(window as any).storageUrlShort = "https://webservice.monitoring-app.de/";
(window as any).apiUrlDebug = "https://m2ing-admin.mmc-entwicklung.de/api/";
(window as any).storageUrlDebug = "https://m2ing-admin.mmc-entwicklung.de/uploads/";
(window as any).storageUrlShortDebug = "https://m2ing-admin.mmc-entwicklung.de/";
(window as any).building_temperature = 21;
// (window as any).apiUrl = "http://localhost:8000/api/";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent {
  public appPages = [
    {
      title: 'Projekte',
      url: '/projects',
      icon: 'home'
    },
    {
      title: 'Synchronisation',
      url: '/sync',
      icon: 'sync'
    },
    {
      title: 'Feedback',
      url: '/feedback',
      icon: 'chatbubble'
    },
    {
      title: 'FAQ',
      url: '/faq',
      icon: 'help'
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'key'
    }
  ];

  constructor(
    private platform: Platform,
    private ss: SavestateService
  ) {
    this.initializeApp();
  }

  async initializeApp() {    
    console.log('Init Data from App Component');
    await this.ss.reloadData();
  }
}
