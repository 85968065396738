import { Plugins } from "@capacitor/core";
import { Storage } from '@capacitor/storage';

export async function clear(): Promise<void> {
  await Storage.clear();
} 

export async function set(key: string, value: any): Promise<void> {
  await Storage.set({
    key: key,
    value: JSON.stringify(value)
  });
}

export async function get(key: string): Promise<any> {
  const item = await Storage.get({ key: key });

  let value;
  try {
    value = JSON.parse(item.value);
  } catch (e) {
    value = item.value;
  }
  console.log("Loading Data in storage helper" + key);
  // console.log("Getting Data " + value);
  return value;
}

export async function remove(key: string): Promise<void> {
  await Storage.remove({ 
    key: key
  });
}